import React, { useState } from 'react';
import { Box, AspectRatio, Text } from '@chakra-ui/react';
import loadable from '@loadable/component';
import useIntersect from '@jackyef/use-intersect';

import FilterSection from '@/components/Section/Filter'
import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';

import { COVER_GALLERY_1 } from '@/constants/assets';
import { ENABLE_WEDDING_FILTER, ENABLE_QUOTES, ENABLE_VIDEO_PREWEDDING_ALTERNATIVE } from '@/constants/features-flag';

const LazyGallery = loadable(() => import(/* webpackChunkName: "lazy-gallery" */ './Gallery'));

const optionsData = {
  root: null,
  rootMargin: '0px',
  threshold: [0, 0, 0, 0],
};

/**
 * function to render Photo Slider
 * This component using `react-image-gallery`
 * @returns {JSX.Element}
 */
function PhotoSliderSection({ ...rest }) {
  const [loaded, setLoaded] = useState(false);

  const onIntersect = () => {
    setLoaded(true);
  };

  const targetRef = useIntersect(onIntersect, optionsData, true);

  return (
    <Box bgColor="white" padding="32px 16px" {...rest}>
      <Box paddingBottom="0" borderRadius="16px">
        {ENABLE_QUOTES && (
          <WithAnimation>
            <Text align="center" color="mainColorText" fontWeight="normal" fontSize="sm" marginBottom="24px">
              And I knew exactly how old Walt Disney’s <br />
              Cinderella felt when she found her prince. <br />
              - Elizabeth Young -
            </Text>
          </WithAnimation>
        )}
        {ENABLE_VIDEO_PREWEDDING_ALTERNATIVE && (
          <WithAnimation>
            <AspectRatio maxW="100%" ratio={16 / 9} borderRadius="lg" marginBottom="16px">
              <iframe
                src="https://www.youtube.com/embed/AhGpYiRxqlw"
                allowFullScreen
                loading="lazy"
                style={{ borderRadius: '16px' }}
              />
            </AspectRatio>
          </WithAnimation>
        )}
        <WithAnimation>
          <Image src={COVER_GALLERY_1} borderRadius="16px" marginBottom="16px" width="100%" />
        </WithAnimation>
        <WithAnimation>
          <Box ref={targetRef}>{loaded ? <LazyGallery /> : 'Loading gallery...'}</Box>
        </WithAnimation>
        <Box height="32px" />
      </Box>
      {ENABLE_WEDDING_FILTER && (
        <FilterSection />
      )}
    </Box>
  );
}

export default React.memo(PhotoSliderSection);